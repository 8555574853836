"use client";

import SimplePostLink from '@/components/objects/SimplePostLink/SimplePostLink';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { Suspense } from "react";
import { PageMap } from "../../../_settings/_configs/SiteInfo";
import styles from "./HomeFlashNewsItems.module.scss";

export type HomeFlashNewsItemsType = {
  listItem: HomeFlashNewsItemType[];
  postsPerPage?: number;
}

export type HomeFlashNewsItemType = {
  id: string;
  date?: string;
  title: string;
  publishedAt?: string;
  label?: string;
  endpoint?: string;
  fontColor?: string;
}

const HomeFlashNewsItems = (props: HomeFlashNewsItemsType) => {
  const {listItem, postsPerPage} = props;
  const [emblaRef] = useEmblaCarousel({ loop: true, axis: 'y' }, [Autoplay()])

  return (
    <>
      <Suspense>
        {listItem ? (
          <div className={styles.simple_listTop}>
            <div className={styles.simple_listTop__left}>            
              <div className={styles.simple_listTop__title}>
                NEWS
              </div>
              <div className={styles['embla']}  ref={emblaRef}>
                <ul className={`${styles.simple_listTop__inner} ${styles['embla__container']}`}>
                  {listItem.slice(0, postsPerPage).map((item: HomeFlashNewsItemType, key: number) => (
                    <li key={key} className={`${styles.simple_listTop__item} ${styles['embla__slide']}`}>
                      <SimplePostLink
                        id={item.id}
                        date={item.publishedAt}
                        title={item.title}
                        label={item.label}
                        endpoint={item.endpoint as string} // 型キャストを使用
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <a href={PageMap["news"].linkTo} className={styles.simple_listTop__link}>VIEW ALL</a>
          </div>
        ) : null}
      </Suspense>
      
    </>
  )
}

export default HomeFlashNewsItems;