"use client";
import HomeFlashNewsItems from "../../../../app/_home/components/HomeFlashNewsItems/HomeFlashNewsItems";
import { PostQueriesType } from "../../../../app/_settings/_configs/Endpoints";
import useFetchLatestContents from "../../../hooks/useFetchLatestContents.hooks";

const PostsLatestBoxTop = (props: PostQueriesType) => {
  const {endpoints, postsPerPage} = props;

  const {isLoading, response, hasError, errorMessage} = useFetchLatestContents(endpoints, postsPerPage);

  if (isLoading) {
    return (
      <></>
    );
  };

  if (hasError) {
    return (
      <p>{errorMessage}</p>
    );
  }

  return (
    <HomeFlashNewsItems
      listItem={response.contents}
      postsPerPage={postsPerPage}
    />
  )
}

export default PostsLatestBoxTop;