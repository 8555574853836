import { Endpoints } from "../../../../app/_settings/_configs/Endpoints";
import getDiffDays from "../../../utils/getDiffDays";
import getFormatedDate from "../../../utils/getFormatedDate";
import styles from "./SimplePostLink.module.scss";
import { SimplePostLinkType } from "./SimplePostLink.types";

const SimplePostLink = (props: SimplePostLinkType) => {
  const {id, date, title, label, endpoint, fontColor} = props;

  const isNew = date && (getDiffDays(date) <= 7)? true: false ;
  const slug = endpoint && Endpoints[endpoint]["news"].archivePath;

  return (
    <a href={`${slug}post/?id=${id}`} className={styles.post_link}>
      <div className={styles.post_link__date}>
        {/* <div className={styles.post_linke__label}>{label === 'news' ? label : label + "院"}</div> */}
        <div className={styles.post_link__label}>{label}</div>
        <span>{date ? getFormatedDate(date,"yyyymmdd") : null}</span>
      </div>
      <span className={styles.post_link__title}>{title}</span>
    </a>
  )
}

export default SimplePostLink;